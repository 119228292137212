import styled from 'styled-components';

import THEME_CONSTS from '~/consts/theme';

const ButtonBase = styled.button`
    cursor: pointer;
    font-family: inherit;
    text-transform: inherit;
    
    transition: all 0.3s;

    background: linear-gradient(transparent, rgba(0, 0, 0, 0.2)) top/100% 800%;

    border: 1px solid #fff;
    
    &:hover {
        background-position: bottom;
    }

    &:focus{
        border: 1px solid ${THEME_CONSTS.HEADER_BG_COLOR};
    }

    &[disabled] {
        cursor: not-allowed;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none;
        opacity: .65;
    }
`;

export default ButtonBase;