import React, { useState } from "react";

// SERVICES
import AuthService from "~/services/auth";
import APIRequests from "~/services/requests/api";

// BOOTSTRAP
import Row from "~/components/Bootstrap/Row";
import Label from "~/components/Bootstrap/Label";
import { ColSm2, ColSm4, ColSm5, ColSm6 } from "~/components/Bootstrap/Col";

// COMPONENTES DE BASEPAGE
import { BasePageMain, BasePageContainer, BasePageBox, BasePageTitleContainer, BasePageTitle, } from "~/components/BasePage";

// COMPONENTES GERAIS
import InputSmall from "~/components/Inputs/Normal/Small";
import TextareaNormal from "~/components/Textareas/Normal";
import ButtonPrimary from '~/components/Buttons/Small/Primary';
import NavigationBar from "~/components/NavigationBar";

// UTILS
import Utils from "~/utils";
import NotificacaoUtil from "~/utils/notificacao";

export default function PageFerramentasCertificadoDigital() {

    const [ArquivoPFX, setArquivoPFX] = useState();
    const [SenhaCertificado, setSenhaCertificado] = useState('');

    const [ChavePublica, setChavePublica] = useState('')
    const [ChavePrivada, setChavePrivada] = useState('')

    const onRequestConfirm = async () => {
        try {

            if (!ArquivoPFX) {
                throw new Error("Selecione o certificado digital");
            }

            if (!SenhaCertificado) {
                throw new Error("Informe a senha do certificado digital");
            }

            /**
             * Converte o certificado pra base64
             */
            const certificado = await Utils.ConverteArquivoBase64(ArquivoPFX)

            /**
             * Solicita a conversão
             */
            const data = await APIRequests.utilidades.converter_certificado({
                certificado: certificado,
                senha: SenhaCertificado
            })

            if (data.public && data.private) {
                setChavePublica(data.public);
                setChavePrivada(data.private);
            }

        } catch (error) {
            NotificacaoUtil.error({
                msg: error?.message?.replaceAll(`\n`, "<br />") || '',
                timeout: 3500,
            });
        }

    }

    return (
        <BasePageMain id="main">
            <NavigationBar />

            <BasePageContainer id="container">

                <BasePageTitleContainer>
                    <BasePageTitle>
                        Conversor Certificado Digital
                    </BasePageTitle>
                </BasePageTitleContainer>

                <BasePageBox id="box" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Row>
                        <ColSm6>
                            <Label>Arquivo PFX</Label>
                            <InputSmall
                                accept=".pfx"
                                type="file"
                                onChange={e => setArquivoPFX(e.target.files[0])}
                            />
                        </ColSm6>
                        <ColSm4>
                            <Label>Senha Certificado</Label>
                            <InputSmall
                                value={SenhaCertificado}
                                type="password"
                                onChange={e => setSenhaCertificado(e.target.value)}
                                onKeyPress={e => {
                                    const code = e.which || e.keyCode;

                                    if (code == 13) {
                                        onRequestConfirm();
                                    }
                                }}
                            />
                        </ColSm4>
                        <ColSm2>
                            <Label>&nbsp;</Label>
                            <ButtonPrimary
                                style={{ width: '100%' }}
                                onClick={onRequestConfirm}
                            >
                                Extrair chave pública
                            </ButtonPrimary>
                        </ColSm2>
                    </Row>

                    <Row style={{ display: 'flex', flexGrow: 1, marginBottom: 0 }}>
                        <ColSm6 style={{ display: 'flex', flexDirection: 'column' }}>
                            <Label>Chave pública extraída</Label>
                            <TextareaNormal
                                readOnly
                                // rows={25}
                                value={ChavePublica}
                                style={{ resize: 'vertical', height: '100%' }}
                            />
                        </ColSm6>
                        <ColSm6 style={{ display: 'flex', flexDirection: 'column' }}>
                            {/* TODO: Adicionar permissão para validar se o usuário pode ver a chave privada */}
                            <Label>Chave privada extraída</Label>
                            <TextareaNormal
                                readOnly
                                // rows={25}
                                value={ChavePrivada}
                                style={{ resize: 'vertical', height: '100%' }}
                            />
                        </ColSm6>
                    </Row>

                </BasePageBox>
            </BasePageContainer>

        </BasePageMain >
    );
}
