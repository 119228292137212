import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// SERVICES
import AuthService from "~/services/auth";
import APIRequests from "~/services/requests/api";

// COMPONENTES GERAIS
import Header from "~/components/Header";
import HeaderLogo from "~/components/Header/Logo";
import HeaderItem from "~/components/Header/Item";
import HeaderItemN1 from "~/components/Header/ItemN1";

export default function NavigationBar() {
    const navigate = useNavigate();

    /**
     * Nome do usuário logado
     */
    const [NomeUsuarioLogado, setNomeUsuarioLogado] = useState("USUÁRIO");

    const onRequestHome = () => {
        navigate("/app");
    };

    const onRequestCadAdministradores = () => {
        navigate("/app/cadastros/administradores");
    };

    const onRequestCadEmitentes = () => {
        navigate("/app/cadastros/emitentes");
    };

    const onRequestUtilCertificadoDigital = () => {
        navigate("/app/ferramentas/certificado-digital");
    };

    const onRequestReports = () => {
        navigate("/app/relatorios");
    };

    const onRequestContratos = () => {
        navigate("/app/contratos");
    };

    const onRequestAtualiacoes = () => {
        navigate("/app/atualizacoes");
    };

    const onRequestPDVsNFCe = () => {
        navigate("/app/pdvs-nfce");
    };

    const onRequestSelfCheckouts = () => {
        navigate("/app/self-checkouts");
    };

    const onRequestLogout = async () => {
        // efetua o logout
        await AuthService.logout();

        // volta pro login
        navigate("/");
    };

    const goLoadMeData = async () => {
        const me = await APIRequests.administradores.me();

        if (me.id) {
            // atualiza os dados do usuário no storage
            AuthService.setUserData(me);

            // atualiza o nome do usuário logado
            setNomeUsuarioLogado(me?.nome?.toUpperCase());
        }
    };

    const goLoadUsuarioLogado = () => {
        const usuario = AuthService.getUserData();
        setNomeUsuarioLogado(usuario?.nome?.toUpperCase());
    };

    /**
     * Carrega os endereços pendentes.
     */
    useEffect(() => {
        goLoadMeData();
        goLoadUsuarioLogado();
    }, []);

    return (
        <Header>
            <HeaderLogo onClick={onRequestHome} />

            <HeaderItem icon="fal fa-book" title="CADASTROS" onClick={() => {}}>
                {AuthService.checkModule("administradores") && (
                    //
                    <HeaderItemN1 title="ADMINISTRADORES" icon="fad fa-user-crown" onClick={onRequestCadAdministradores} />
                )}
                {AuthService.checkModule("emitentes") && (
                    //
                    <HeaderItemN1 title="EMITENTES" icon="fad fa-users" onClick={onRequestCadEmitentes} />
                )}
                {/* <HeaderItemN1 title='LICENÇAS' icon="fal fa-key" onClick={onRequestCadLicencas} /> */}
            </HeaderItem>

            <HeaderItem icon="fal fa-toolbox" title="FERRAMENTAS" onClick={() => {}}>
                <HeaderItemN1 title="CERTIFICADO DIGITAL" icon="fad fa-file-certificate" onClick={onRequestUtilCertificadoDigital} />
            </HeaderItem>

            <div style={{ float: "right" }}>
                {/* {AuthService.checkModule('relatorios') && (
                )} */}
                {/* <HeaderItem icon="fal fa-chart-bar" title='RELATÓRIOS' onClick={onRequestReports} /> */}

                <HeaderItem icon="fal fa-user" title={NomeUsuarioLogado} onClick={() => {}} dropdownMenuPosition="right">
                    <HeaderItemN1 icon="fad fa-sign-out-alt" title="SAIR" onClick={onRequestLogout} />
                </HeaderItem>
            </div>
        </Header>
    );
}
