import axios from "axios";

const api = axios.create({
    baseURL: 'https://api-painel-linvix.linvix.com/v1',
    timeout: 100000, // valor alto por causa do relatório
    headers: {
        "Content-type": "application/json"
    },
});

const CNPJ = async (cnpj = '') => {

    try {

        const { data: response_data } = await api.get(`/public/lambda/cnpj/${cnpj}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const CEP = async (cep = '') => {

    try {

        const { data: response_data } = await api.get(`/public/lambda/cep/${cep}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}


const LambdaRequests = {
    cnpj: CNPJ,
    cep: CEP,
};

export default LambdaRequests;