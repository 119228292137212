import axios from "axios";

// SERVICES
import AuthService from "~/services/auth";

const api = axios.create({
    timeout: 100000, // valor alto por causa do relatório
    headers: {
        "Content-type": "application/json"
    },
});

// INTERCEPTA A CONEXÃO AXIOS
api.interceptors.request.use(
    async function (config) {

        const development = process.env.NODE_ENV != "production";

        const REACT_APP_API_URL = process.env.REACT_APP_API_URL ?? 'localhost';
        const REACT_APP_DEV_API_URL = process.env.REACT_APP_DEV_API_URL ?? 'localhost';

        // configuração da url
        config.baseURL = development ? `http://${REACT_APP_DEV_API_URL}` : `https://${REACT_APP_API_URL}`;

        config.headers.AuthorizationSource = `painel`;

        // pega o token de autenticação
        const token = AuthService.getToken();

        // adiciona o header
        if (token) {
            config.headers.Authorization = `Basic ${token}`;
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Adicionando um interceptor de Response
api.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    // 401 é desautorizado, tem que redirecionar
    if (error.response.status === 401) {
        // força o logout
        AuthService.logout();

        // redireciona para o login
        window.useNavigate('/');
    }

    // Quaisquer códigos de status que estejam fora de 2xx fazem com que esta função seja acionada
    // Faça algo com erro de resposta
    return Promise.reject(error);
});

export default api;