import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import { useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

// BOOTSTRAP
import Hr from "~/components/Bootstrap/Hr";
import Row from "~/components/Bootstrap/Row";
import Label from "~/components/Bootstrap/Label";
import { ColSm12, ColSm3, ColSm4, ColSm6, ColSm8 } from "~/components/Bootstrap/Col";

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";

// STYLED COMPONENTS
import { Main, Content, Footer } from "./styles";

// INPUTS
import InputNormal from "~/components/Inputs/Normal/Normal";
import InputFormNormal from "~/components/Inputs/Form/Normal";
import SelectFormNormal from "~/components/Selects/Form/Normal";

// BUTONS
import ButtonPrimary from "~/components/Buttons/Normal/Primary";
import ButtonSecondary from "~/components/Buttons/Normal/Secondary";

// UTILS
import Utils from "~/utils";
import NotificacaoUtil from "~/utils/notificacao";

// SERVICES
import AuthService from "~/services/auth";
import APIRequests from "~/services/requests/api";

const PSPsHomologados = {
    banco_brasil: "Banco do Brasil",
    "banco_brasil-v2": "Banco do Brasil v2",
    "bradesco-v2": "Banco Bradesco v2",
    "santander-v2": "Banco Santander v2",
    "sicoob-v2": "Banco Sicoob v2",
    "sicredi-v2": "Banco Sicredi v2",
    "inter-v2": "Banco Inter v2",
    "itau-v2": "Banco Itaú v2",
};

type PSPsHomologadosEnum = keyof typeof PSPsHomologados;

type PSPConfigsBase = {
    certificado_unico_a1: boolean;
    certificado_crt_individual?: boolean;
    certificado_key_individual?: boolean;
};

type PSPConfigs = Record<PSPsHomologadosEnum, PSPConfigsBase>;

export interface ModalCadastroEdicaoEmitentesPSPForwardRef {
    open: (uuid: string) => Promise<void>;
    close: () => any;
}

interface Props {
    ref: React.ForwardedRef<React.FC>;

    onConfirmCadEdit?: () => void;
}

const ModalCadastroEdicaoEmitentesPSP = forwardRef<ModalCadastroEdicaoEmitentesPSPForwardRef, Props>(({ onConfirmCadEdit = () => {} }, ref) => {
    /**
     * UUID do emitente na URL.
     */
    const { emitente_id } = useParams();

    /**
     * Configurações do PSP
     */
    const PSPConfigs: PSPConfigs = {
        banco_brasil: { certificado_unico_a1: false },
        "banco_brasil-v2": { certificado_unico_a1: false, certificado_crt_individual: true, certificado_key_individual: true },
        "bradesco-v2": { certificado_unico_a1: true },
        "santander-v2": { certificado_unico_a1: true },
        "sicoob-v2": { certificado_unico_a1: true },
        "sicredi-v2": { certificado_unico_a1: false, certificado_crt_individual: true, certificado_key_individual: true },
        "inter-v2": { certificado_unico_a1: false, certificado_crt_individual: true, certificado_key_individual: true },
        "itau-v2": { certificado_unico_a1: false },
    };

    /**
     * Qual PSP que está habilitado
     */
    const [PSP, setPSP] = useState<string>("");

    /**
     * Modal aberto ou fechado
     */
    const [isVisible, setVisible] = useState(false);

    /**
     * UUID do registro
     */
    const [UUID, setUUID] = useState("");

    /**
     * Config PSP atual
     */
    const PSPConfig = PSPConfigs[PSP as PSPsHomologadosEnum];

    /**
     * Cria a referencia do formulário.
     */
    const FormREF = useRef<FormHandles>(null);

    /**
     * Dados iniciais do form.
     */
    const [FormInitialData, setFormInitialData] = useState<any>({});

    /**
     * Atualização do certificado digital A1
     */
    const [SubstituirCertificado, setSubstituirCertificado] = useState(false);
    const [ArquivoPFX, setArquivoPFX] = useState<FileList | null>();
    const [SenhaPFX, setSenhaPFX] = useState("");

    /**
     * Atualização dos certificados individuais
     */
    const [ArquivoCRT, setArquivoCRT] = useState<FileList | null>();
    const [ArquivoKEY, setArquivoKEY] = useState<FileList | null>();

    const onRequestConfirm = async () => {
        FormREF?.current?.submitForm();
    };

    const onSubmit = async (data: any) => {
        try {
            if (!data.psp) {
                throw new Error("Selecione o PSP desejado.");
            }

            if (!data.ambiente) {
                throw new Error("Selecione um ambiente.");
            }

            // verifica se tem o certificado a1 informado
            if (ArquivoPFX && ArquivoPFX?.length > 0) {
                data = {
                    ...data,
                    certificado: await Utils.ConverteArquivoBase64(ArquivoPFX[0]),
                    certificado_senha: SenhaPFX,
                };
            }

            // verifica se é certificado a1 ou individual
            if (ArquivoCRT && ArquivoCRT?.length > 0) {
                data = {
                    ...data,
                    certificado_crt: await Utils.ConverteArquivoBase64(ArquivoCRT[0]),
                };
            }

            if (ArquivoKEY && ArquivoKEY?.length > 0) {
                data = {
                    ...data,
                    certificado_key: await Utils.ConverteArquivoBase64(ArquivoKEY[0]),
                };
            }

            let retorno_api: any = {};

            if (UUID) {
                retorno_api = await APIRequests.psps.atualizar(emitente_id, UUID, data);
            } else {
                retorno_api = await APIRequests.psps.adicionar(emitente_id, data);
            }

            if (retorno_api?.id) {
                NotificacaoUtil.success({
                    msg: UUID ? `PSP Emitente atualizado com sucesso` : "PSP Emitente cadastrado com sucesso",
                });

                // fecha o modal
                onRequestClose();

                // dispara a função externa
                onConfirmCadEdit();
            } else {
                NotificacaoUtil.error({
                    msg: UUID ? `Erro ao atualizar o psp emitente` : `Erro ao cadastrar o psp emitente`,
                    timeout: 3500,
                });
            }
        } catch (error: Error | any) {
            NotificacaoUtil.error({
                msg: error?.message?.replaceAll(`\n`, "<br />") || "",
                timeout: 3500,
            });
        }
    };

    const onRequestOpen = async (uuid = "") => {
        let visible = true;

        if (uuid) {
            const dados_psp = await APIRequests.psps.obter(emitente_id, uuid);

            if (dados_psp.uuid) {
                /**
                 * Armazena o UUID.
                 */
                setUUID(dados_psp.uuid);

                /**
                 * PSP escolhido.
                 */
                setPSP(dados_psp.psp);

                /**
                 * Carrega os dados adicionais
                 */
                setFormInitialData(dados_psp);
            } else {
                visible = false;
                new Noty({
                    type: "error",
                    timeout: 2500,
                    text: "Falha ao carregar os dados do emitente.",
                }).show();
            }
        }

        if (visible) {
            setVisible(visible);
        }
    };

    /**
     * Precisa renderizar o certificado a1?
     */
    const needRenderA1Cert = () => {
        if (PSPConfig?.certificado_unico_a1 === true) {
            // se for pra substituir o certificado
            if (SubstituirCertificado) {
                return true;
            }

            // certificado está configurado já?
            // !não pode ser === false tem que ser ! por causa do undefined
            if (!FormInitialData?.["certificado"]) {
                return true;
            }
        }

        return false;
    };

    const needRenderItauV2RequestCertificate = () => {
        if (PSP === "itau-v2" && UUID) {
            // certificado está configurado já?
            // !não pode ser === false tem que ser ! por causa do undefined
            if (!FormInitialData?.["certificado"]) {
                return true;
            }
        }

        return false;
    };

    /**
     * Precisa renderizar os certificados individuais?
     */
    const needRenderIndividualCerts = () => {
        if (PSPConfig?.certificado_crt_individual === true || PSPConfig?.certificado_key_individual === true) {
            // se for pra substituir o certificado
            if (SubstituirCertificado) {
                return true;
            }

            // certificado está configurado já?
            // !não pode ser === false tem que ser ! por causa do undefined
            if (!FormInitialData?.["certificado"]) {
                return true;
            }
        }

        return false;
    };

    const onRequestItauV2Certificate = async () => {
        try {
            const client_id = FormREF.current?.getFieldValue("client_id");
            const token_temporario = FormREF.current?.getFieldValue("itauv2_token_temporario");

            if (!client_id) {
                throw new Error("Client ID obrigatório");
            }

            if (!token_temporario) {
                throw new Error("Token temporário obrigatório");
            }

            const retorno_api = await APIRequests.utilidades.solicitar_certificado_itau_v2({
                psp_uuid: UUID,
                token_temporario: token_temporario,
            });

            if (retorno_api?.uuid) {
                NotificacaoUtil.success({
                    msg: `Certificado gerado com sucesso`,
                });

                // fecha o modal
                onRequestClose();

                // dispara a função externa
                onConfirmCadEdit();
            } else {
                NotificacaoUtil.error({
                    msg: `Erro ao solicitar o certificado no itaú`,
                    timeout: 3500,
                });
            }
        } catch (error: Error | any) {
            NotificacaoUtil.error({
                msg: error?.message?.replaceAll(`\n`, "<br />") || "",
                timeout: 3500,
            });
        }
    };

    const onRequestClose = () => {
        setVisible(false);

        // reseta o estado
        setPSP("");
        setUUID("");
        setFormInitialData({});

        setArquivoPFX(null);
        setSenhaPFX("");
        setSubstituirCertificado(false);

        setArquivoCRT(null);
        setArquivoKEY(null);
    };

    const onKeyDown = (e) => {
        // pega o código pressionado
        const code = e.which !== false ? e.which : e.keyCode;

        // stop events
        if (code == 13 || code == 27) {
            e.preventDefault();
        }

        if (code == 13) {
            onRequestConfirm();
        }

        if (code == 27) {
            onRequestClose();
        }
    };

    /**
     * Carrega os dados no form
     */
    useEffect(() => {
        if (FormREF?.current) {
            if (FormInitialData?.uuid) {
                FormREF?.current.setData(FormInitialData);
            } else {
                FormREF?.current.reset();
            }
        }
    }, [isVisible, FormREF, FormInitialData]);

    /**
     * Passa a função de buscar para fora do input via ref.
     */
    useImperativeHandle(ref, () => ({
        open: onRequestOpen,
        close: onRequestClose,
    }));

    return (
        <Modal isVisible={isVisible} setVisible={onRequestClose} closeButtonVisible={false} closeOnClickOutside={false} width={650} height={"100%"}>
            <Main>
                <ModalHeader>{UUID ? "Edição" : "Cadastro"} de PSP</ModalHeader>

                <Content>
                    <Form ref={FormREF} onSubmit={onSubmit}>
                        <Row>
                            <ColSm6>
                                <Label>PSP UUID</Label>
                                {UUID ? <InputFormNormal name={"uuid"} disabled /> : <InputNormal value="GERADO AUTOMATICAMENTE" disabled />}
                            </ColSm6>

                            <ColSm3>
                                <Label>PSP *</Label>
                                <SelectFormNormal disabled={UUID} name="psp" onKeyDown={onKeyDown} onChange={(e) => setPSP(e.target.value)}>
                                    <option value=""></option>

                                    {Object.keys(PSPsHomologados).map((e) => (
                                        <option value={e}>{PSPsHomologados[e]}</option>
                                    ))}
                                </SelectFormNormal>
                            </ColSm3>

                            <ColSm3>
                                <Label>Ambiente *</Label>
                                <SelectFormNormal disabled={UUID} name="ambiente" onKeyDown={onKeyDown}>
                                    <option value=""></option>
                                    <option value="p">Produção</option>
                                    <option value="h">Homologação</option>
                                </SelectFormNormal>
                            </ColSm3>
                        </Row>

                        <br />
                        <Hr />
                        <br />

                        {/* verifica se precisa solicitar o certificado do itau */}
                        {needRenderItauV2RequestCertificate() ? (
                            <>
                                <Row>
                                    <ColSm12>
                                        <Label>Client ID</Label>
                                        <InputFormNormal name="client_id" onKeyDown={onKeyDown} />
                                    </ColSm12>
                                </Row>

                                <Row>
                                    <ColSm12>
                                        <Label>Token temporário</Label>
                                        <InputFormNormal name="itauv2_token_temporario" onKeyDown={onKeyDown} />
                                    </ColSm12>
                                </Row>

                                <br />
                                <ButtonPrimary type="button" onClick={onRequestItauV2Certificate} style={{ width: "100%" }}>
                                    Solicitar certificado digital
                                </ButtonPrimary>
                                <br />
                            </>
                        ) : (
                            <>
                                <Row>
                                    <ColSm12>
                                        <Label>Client ID</Label>
                                        <InputFormNormal name="client_id" onKeyDown={onKeyDown} />
                                    </ColSm12>
                                </Row>

                                <Row>
                                    <ColSm12>
                                        <Label>Client Secret</Label>
                                        <InputFormNormal name="client_secret" onKeyDown={onKeyDown} />
                                    </ColSm12>
                                </Row>
                            </>
                        )}

                        <br />
                        <Hr />
                        <br />

                        {/* SE FOR CERTIFICADO ÚNICO A1 */}
                        {PSPConfig?.certificado_unico_a1 === true && (
                            <>
                                {needRenderA1Cert() && (
                                    <Row>
                                        <ColSm8>
                                            <Label>Certificado Digital</Label>
                                            <InputNormal type="file" onChange={(e) => setArquivoPFX(e.target.files)} />
                                        </ColSm8>
                                        <ColSm4>
                                            <Label>Senha Certificado Digital</Label>
                                            <InputNormal type="password" onChange={(e) => setSenhaPFX(e.target.value)} onKeyDown={onKeyDown} />
                                        </ColSm4>
                                    </Row>
                                )}

                                {FormInitialData?.["certificado"] === true && FormInitialData?.["certificado_vencido"] === true && (
                                    <Row>
                                        <ColSm12>
                                            <center style={{ color: "red" }}>
                                                <b>CERTIFICADO DIGITAL VENCIDO EM {FormInitialData?.["certificado_validade"]}</b>
                                            </center>
                                            <br />

                                            <b>
                                                <center>Só atualize o certificado digital se tiver recurso para atualizar certificado digital no site do PSP</center>
                                                <center>Alguns PSP's não precisam atualizar o certificado digital mesmo após o vencimento</center>
                                            </b>
                                        </ColSm12>
                                    </Row>
                                )}
                            </>
                        )}

                        {/* SE FOR CERTIFICADOS INDIVIDUAIS */}
                        {needRenderIndividualCerts() && (
                            <Row>
                                {PSPConfig?.certificado_crt_individual === true && (
                                    <ColSm6>
                                        <Label>Certificado Digital (CRT)</Label>
                                        <InputNormal type="file" onChange={(e) => setArquivoCRT(e.target.files)} />
                                    </ColSm6>
                                )}

                                {PSPConfig?.certificado_key_individual === true && (
                                    <ColSm6>
                                        <Label>Certificado Digital (KEY)</Label>
                                        <InputNormal type="file" onChange={(e) => setArquivoKEY(e.target.files)} />
                                    </ColSm6>
                                )}
                            </Row>
                        )}

                        {FormInitialData?.["certificado"] === true && !FormInitialData?.["certificado_vencido"] && SubstituirCertificado === false && (
                            <Row>
                                <ColSm12>
                                    <center
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setSubstituirCertificado(true);
                                        }}>
                                        <b>Certificado digital já está configurado</b>
                                    </center>
                                </ColSm12>
                            </Row>
                        )}

                        <Footer>
                            <ButtonSecondary type="button" onClick={onRequestClose}>
                                Fechar
                            </ButtonSecondary>

                            {AuthService.checkPermission("emitentes", "editar") && (
                                //
                                <ButtonPrimary type="submit">OK</ButtonPrimary>
                            )}
                        </Footer>
                    </Form>
                </Content>
            </Main>
        </Modal>
    );
});

export default ModalCadastroEdicaoEmitentesPSP;
