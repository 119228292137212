// NAVBAR
const HEADER_HEIGTH = "62px";
const HEADER_MBOTTOM = "0px";
const OFF_CONTAINER_HEIGHT = "30px";
const HEADER_BG_COLOR = '#384252';
const ITEM_HOVER_COLOR = '#4b5668';
const TOP_LINE_COLOR = '#fc913a';

const BG_COLOR = '#eceff1';

const FONT_PRIMARY_COLOR = '#4a4343';
const PAGE_TITLE_FONT_COLOR = '#554b7e';

const BUTTON_PRIMARY_BG_COLOR = '#4b5668';
const BUTTON_PRIMARY_FONT_COLOR = '#fff';

const BUTTON_SECONDARY_BG_COLOR = '#CCCCCC';
const BUTTON_SECONDARY_FONT_COLOR = '#5a5a5a';

// const BUTTON_DANGER_BG_COLOR = '#D36464'; // original
const BUTTON_DANGER_BG_COLOR = '#f6464a';
const BUTTON_DANGER_FONT_COLOR = '#fff';

const BUTTON_SUCCESS_BG_COLOR = '#22998B';
const BUTTON_SUCCESS_FONT_COLOR = '#fff';

export default {

    HEADER_HEIGTH,
    HEADER_MBOTTOM,
    OFF_CONTAINER_HEIGHT,
    HEADER_BG_COLOR,
    ITEM_HOVER_COLOR,
    TOP_LINE_COLOR,


    BG_COLOR,
    FONT_PRIMARY_COLOR,
    PAGE_TITLE_FONT_COLOR,

    BUTTON_PRIMARY_BG_COLOR,
    BUTTON_PRIMARY_FONT_COLOR,

    BUTTON_SECONDARY_BG_COLOR,
    BUTTON_SECONDARY_FONT_COLOR,

    BUTTON_DANGER_BG_COLOR,
    BUTTON_DANGER_FONT_COLOR,

    BUTTON_SUCCESS_BG_COLOR,
    BUTTON_SUCCESS_FONT_COLOR,

}