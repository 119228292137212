import styled from 'styled-components';
import ButtonBaseNormal from './Base';

import THEME_CONSTS from '~/consts/theme';

const ButtonPrimaryNormal = styled(ButtonBaseNormal)`
    color: ${THEME_CONSTS.BUTTON_PRIMARY_FONT_COLOR};
    background-color: ${THEME_CONSTS.BUTTON_PRIMARY_BG_COLOR};
    border: 1px solid ${THEME_CONSTS.BUTTON_PRIMARY_BG_COLOR};
`
export default ButtonPrimaryNormal;