import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";

// SERVICES
import AuthService from "~/services/auth";
import APIRequests from "~/services/requests/api";

// COMPONENTES GERAIS
import SearchInput from "~/components/SearchInput";

import Row from "~/components/Bootstrap/Row";

// COMPONENTES DE BASEPAGE
import {
    BasePageMain,
    BasePageContainer,
    BasePageBox,
    BasePageTitleContainer,
    BasePageTitle,
    BasePageButtonsContainer,
    BasePageTopLineSeparator,
    BasePageButtonsContainerRight,
} from "~/components/BasePage";

// TABLE
import Table from "~/components/TableR";

// COMPONENTES GERAIS
import NavigationBar from "~/components/NavigationBar";
import ButtonPrimarySmall from "~/components/Buttons/Small/Primary";

// COMPONENTES INTERNOS
import ModalCadastroEdicao from "./components/ModalCadEdit";

// UTILS
import { ColSm3, ColSm9 } from "~/components/Bootstrap/Col";

export default function PageCadastroPSPs() {
    const { emitente_id } = useParams();

    const RefModalCad = useRef(null);

    /**
     * Dados de navegação
     */
    const [NavSorted, setNavSorted] = useState([]);
    const [NavPageCount, setNavPageCount] = useState(0);
    const [NavCurrentPage, setNavCurrentPage] = useState(1);
    const [NavLimitPerPage, setNavLimitPerPage] = useState(10);
    const [NavTotalRows, setNavTotalRows] = useState(0);

    /**
     * Lista de PSPs
     */
    const [PSPs, setPSPs] = useState([]);

    const [Pesquisa, setPesquisa] = useState("");
    const [Pesquisar, setPesquisar] = useState(false);

    const [NomeEmitente, setNomeEmitente] = useState("");

    /**
     * Carrega o nome do emitente para colocar no título da página.
     */
    const goLoadEmitenteData = async () => {
        const Emitente = await APIRequests.emitentes.obter(emitente_id);

        if (Emitente.uuid) {
            setNomeEmitente(Emitente.nome);
        }
    };

    /**
     * Carrega os PSPs da API
     */
    const goLoadPSPs = async () => {
        const PSPs = await APIRequests.psps.listar({
            query: Pesquisa,
            page: NavCurrentPage,
            limit: NavLimitPerPage,
            sorted: NavSorted,
            emitente: emitente_id,
        });

        if (PSPs.results) {
            setPSPs(PSPs.results);
            setNavPageCount(PSPs.metadata.page.total);
            setNavTotalRows(PSPs.metadata.total);
        }
    };

    /**
     * Quando navegar na tabela
     */
    const onTableNavChange = useCallback((data) => {
        setNavSorted(data.sortBy);
        setNavLimitPerPage(data.limit);
        setNavCurrentPage(data.page + 1);
    }, []);

    /**
     * Abre o modal para inserir.
     */
    const onRequestAddEmitentePSP = () => {
        RefModalCad?.current?.open();
    };

    /**
     * Abre o modal para editar.
     */
    const onRequestEditEmitentePSP = (uuid) => {
        RefModalCad?.current?.open(uuid);
    };

    /**
     * Ao confirmar o cadastro ou edição do Emitente.
     */
    const onConfirmCadEditEmitentePSP = () => {
        goLoadPSPs();
    };

    /**
     * Dispara requisições para backend.
     */
    useEffect(() => {
        let timeout = setTimeout(() => {
            goLoadPSPs();
        }, 100);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        };
    }, [Pesquisa, NavLimitPerPage, NavCurrentPage, NavSorted]);

    useEffect(() => {
        goLoadEmitenteData();
    }, []);

    /**
     * Colunas da tabela
     */
    const TableColumns = useMemo(
        () => [
            {
                accessor: "uuid",
                width: 20,
                disableSortBy: true,
                Header: (data) => {
                    return <center>#</center>;
                },
                Cell: ({ value, row }) => {
                    return (
                        <center>
                            <ButtonPrimarySmall
                                // disabled={!AuthService.checkPermission('emitentes', 'editar')}
                                onClick={() => onRequestEditEmitentePSP(value)}>
                                <i className="fas fa-eye" />
                            </ButtonPrimarySmall>
                        </center>
                    );
                },
            },
            {
                Header: "PSP",
                accessor: "psp",
            },
            {
                Header: "Situação",
                accessor: "situacao",
                width: 50,
            },
            {
                Header: "Criado em",
                accessor: "criado_em",
                width: 70,
            },
        ],
        [],
    );

    const TableInitialSortBy = [
        {
            id: "id",
            desc: false,
        },
    ];

    return (
        <BasePageMain id="main">
            <NavigationBar />

            <BasePageContainer id="container">
                <BasePageTitleContainer>
                    <BasePageTitle>Cadastro de PSPs - {NomeEmitente}</BasePageTitle>
                </BasePageTitleContainer>

                {AuthService.checkPermission("emitentes", "adicionar") && (
                    <>
                        <BasePageTopLineSeparator />

                        <BasePageButtonsContainer style={{ justifyContent: "space-between" }}>
                            <ButtonPrimarySmall onClick={onRequestAddEmitentePSP}>Adicionar</ButtonPrimarySmall>
                        </BasePageButtonsContainer>
                    </>
                )}

                <BasePageBox id="box">
                    <Row>
                        <ColSm9 />
                        <ColSm3>
                            <SearchInput inputType="small" onChange={(e) => setPesquisar(true)} onSearch={(text) => setPesquisa(text)} />
                        </ColSm3>
                    </Row>

                    <Table
                        nostriped
                        initialSortBy={TableInitialSortBy}
                        pageIndex={NavCurrentPage - 1}
                        pageCount={NavPageCount}
                        totalRows={NavTotalRows}
                        initialPageSize={20}
                        data={PSPs}
                        columns={TableColumns}
                        onChangeNav={onTableNavChange}
                    />

                    {/* <div style={{ marginTop: 10 }}>
                        <Table nostriped>
                            <Thead>
                                <Tr>
                                    <Th width={40}>
                                        <center>#</center>
                                    </Th>
                                    <Th>Nome</Th>
                                    <Th width={220}>E-mail</Th>
                                    <Th width={115}>Celular</Th>
                                    <Th width={100}>Situação</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {PSPs.map((prod) => {
                                    return (
                                        <Tr key={prod.uuid}>
                                            <Td width={40}>
                                                <center>
                                                    <ButtonPrimarySmall onClick={() => onRequestEditEmitentePSP(prod.uuid)}>
                                                        <i className="fas fa-pencil" />
                                                    </ButtonPrimarySmall>
                                                </center>
                                            </Td>
                                            <Td>{prod.nome}</Td>
                                            <Td width={220}>{prod.email}</Td>
                                            <Td width={115}>{prod.celular}</Td>
                                            <Td width={100} style={{ textTransform: 'capitalize' }}>{prod.situacao}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </div> */}
                </BasePageBox>
            </BasePageContainer>

            <ModalCadastroEdicao ref={RefModalCad} onConfirmCadEdit={onConfirmCadEditEmitentePSP} />
        </BasePageMain>
    );
}
