import React, { useEffect, forwardRef } from "react";
import ConfigureMaskInput, { GeraDataMaskKey, AvaliableMaksType } from "../mask";
import InputBaseSmall from "~/components/InputBase/small";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    mask?: AvaliableMaksType;
}

const InputSmall = forwardRef<React.FC, Props>(({ mask = "", ...props }, ref) => {
    const key = GeraDataMaskKey();

    useEffect(() => {
        /**
         * Configura as máscaras.
         */
        ConfigureMaskInput(mask as AvaliableMaksType, key);
    }, []);

    return (
        <>
            <InputBaseSmall //
                ref={ref}
                {...(mask ? { "data-mask-key": key } : {})}
                {...props}
            />
        </>
    );
});

export default InputSmall;
