import React, { useState, useRef, useEffect } from 'react';

// COMPONENTES GERAIS
import InputSmall from "~/components/Inputs/Normal/Small";
import InputNormal from '~/components/Inputs/Normal/Normal';

const SearchInput = ({ onSearch = async (text) => { }, onChange = async (e) => { }, inputType = 'normal', autoFocus = false }) => {

    let InputComponent = null;

    const [SearchText, setSearchText] = useState('');
    const [Searching, setSearching] = useState(false);

    const goSearch = async (text) => {

        // efetua a pesquisa
        await onSearch(text);

        // desativa o loading
        setSearching(false);

    }

    useEffect(() => {
        let timeout = null;
        if (Searching == true) {
            timeout = setTimeout(() => {
                goSearch(SearchText);
            }, 500);
        }
        if (timeout) {
            return () => {
                clearTimeout(timeout);
                timeout = null;
            };
        }
    }, [SearchText, Searching]);

    /**
     * Detecta o tipo do input
     */
    if (inputType == 'small') {
        InputComponent = InputSmall;
    }
    if (inputType == 'normal') {
        InputComponent = InputNormal;
    }

    return (
        <InputComponent
            placeholder="Pesquisar ..."
            autoFocus={autoFocus}
            onChange={(e) => {
                // ativa o loading
                setSearching(true);

                // atualiza o texto
                setSearchText(e.target.value);

                // função onchange externa
                onChange(e);
            }}
        />

    );
}

export default SearchInput;