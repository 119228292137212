// API
import api from '~/services/api';

import { Buffer } from 'buffer';

const url_prefix = '/v1';

const Listar = async ({
    query = '',
    limit = 100,
    page = 1,

    emitente = null,
    emitente_nome = null,

    sorted = []
}) => {

    try {
        const query_params_arr = [];

        query_params_arr.push(`query=${query}`);
        query_params_arr.push(`limit=${limit}`);
        query_params_arr.push(`page=${page}`);

        if (sorted?.length > 0) {
            const sorted_b64 = Buffer.from(JSON.stringify(sorted)).toString('base64');
            query_params_arr.push(`sorted=${sorted_b64}`);
        }

        const query_params = query_params_arr.length > 0 ? `?${query_params_arr.join('&')}` : '';

        const { data: response_data } = await api.get(`${url_prefix}/emitentes/${emitente}/psps${query_params}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Obter = async (emitente = '', uuid = '') => {

    try {

        const { data: response_data } = await api.get(`${url_prefix}/emitentes/${emitente}/psps/${uuid}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Adicionar = async (emitente = '', dados = {}) => {

    try {

        const { data: response_data } = await api.post(`${url_prefix}/emitentes/${emitente}/psps`, dados, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            if (response) {
                throw new Error(response?.erros?.join("\n"));
            } else {
                throw new Error(response.message);
            }
        } else {
            throw error;
        }
    }

}

const Atualizar = async (emitente = '', uuid = '', dados = {}) => {

    try {

        const { data: response_data } = await api.put(`${url_prefix}/emitentes/${emitente}/psps/${uuid}`, dados, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const EmitentesPSPsRequests = {
    listar: Listar,
    obter: Obter,
    adicionar: Adicionar,
    atualizar: Atualizar,
};

export default EmitentesPSPsRequests;