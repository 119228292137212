import styled from 'styled-components';

import THEME_CONSTS from '~/consts/theme';

export const Nav = styled.div`
    width: 100%;
    font-family: 'Roboto Condensed', sans-serif;

    height: ${props => props.height};
    min-height: ${props => props.height};

    position: fixed !important;

    background: ${THEME_CONSTS.HEADER_BG_COLOR};
    border-bottom: 1px solid #5d47b9;
    margin-bottom: ${props => props.margin_bottom};
`;

export const Container = styled.div`
    height: ${props => props.height};
    min-height: ${props => props.height};

    padding: 0 20px;

    position: fixed !important;
    z-index: 999;

    width: calc(100% - (20px * 2));
`;