// API
import api from "~/services/api";

const url_prefix = "/v1";

const ConverterCertificado = async (data = { certificado: "", senha: "" }) => {
    try {
        const { data: response_data } = await api.post(`${url_prefix}/utilidades/converter_certificado`, data);

        return response_data;
    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }
};

const SolicitarCertificadoItauV2 = async (data = { psp_uuid: "", token_temporario: "" }) => {
    try {
        const { data: response_data } = await api.post(`${url_prefix}/utilidades/solicitar_certificado_itau_v2`, data);

        return response_data;
    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }
};

const UtilidadesRequests = {
    converter_certificado: ConverterCertificado,
    solicitar_certificado_itau_v2: SolicitarCertificadoItauV2,
};

export default UtilidadesRequests;
