import React, { useMemo, useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import { Form } from "@unform/web";

// BOOTSTRAP
import Row from "~/components/Bootstrap/Row";
import Hr from "~/components/Bootstrap/Hr";
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm5, ColSm6, ColSm8, ColSm9 } from "~/components/Bootstrap/Col";
import Label from "~/components/Bootstrap/Label";
import SubLabel from "~/components/Bootstrap/SubLabel";

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";

// STYLED COMPONENTS
import { Main, Content, Footer } from "./styles";

// INPUTS
import InputFormNormal from "~/components/Inputs/Form/Normal";
import SelectFormNormal from "~/components/Selects/Form/Normal";

// BUTONS
import ButtonPrimary from "~/components/Buttons/Normal/Primary";
import ButtonSecondary from "~/components/Buttons/Normal/Secondary";

// UTILS
import Utils from "~/utils";
import NotificacaoUtil from "~/utils/notificacao";

// SERVICES
import AuthService from "~/services/auth";
import APIRequests from "~/services/requests/api";

const ModalCadastroEdicao = forwardRef(({ onConfirmCadEdit = () => {} }, ref) => {
    /**
     * Cria a referencia do formulário.
     */
    const FormREF = useRef(null);

    /**
     * Dados iniciais do form.
     */
    const [FormInitialData, setFormInitialData] = useState({});

    const [isVisible, setVisible] = useState(false);

    const [UUID, setUUID] = useState("");

    const onRequestConfirm = async () => {
        FormREF?.current?.submitForm();
    };

    const onRequestSearchCNPJ = async () => {
        try {
            /**
             * Mantém somente os números
             */
            const cnpj = Utils.SomenteNumeros(FormREF?.current?.getFieldValue("documento"));

            if (!cnpj) {
                throw new Error("Informe o CNPJ");
            }

            /**
             * Consulta no lambda.
             */
            const data = await APIRequests.lambda.cnpj(cnpj);

            /**
             * Atualiza no form
             */
            FormREF?.current.setData({
                nome: data?.nome || "",
                fantasia: data?.fantasia || "",
                municipio: data?.municipio || "",
                uf: data?.uf || "",
            });
        } catch (error) {
            NotificacaoUtil.error({
                msg: error?.message?.replaceAll(`\n`, "<br />") || "",
                timeout: 3500,
            });
        }
    };

    const onSubmit = async (data) => {
        try {
            data = {
                ...data,
                // pega o cnpj somente em numeros
                documento: Utils.SomenteNumeros(data.documento),
            };

            let retorno_api = {};

            if (UUID) {
                retorno_api = await APIRequests.emitentes.atualizar(UUID, data);
            } else {
                retorno_api = await APIRequests.emitentes.adicionar(data);
            }

            if (retorno_api?.id) {
                NotificacaoUtil.success({
                    msg: UUID ? `Emitente atualizado com sucesso` : "Emitente cadastrado com sucesso",
                });

                // fecha o modal
                onRequestClose();

                // dispara a função externa
                onConfirmCadEdit();
            } else {
                NotificacaoUtil.error({
                    msg: UUID ? `Erro ao atualizar o emitente` : `Erro ao cadastrar o emitente`,
                    timeout: 3500,
                });
            }
        } catch (error) {
            NotificacaoUtil.error({
                msg: error?.message?.replaceAll(`\n`, "<br />") || "",
                timeout: 3500,
            });
        }
    };

    const onRequestOpen = async (uuid = false) => {
        let visible = true;

        if (uuid) {
            const dados_emitente = await APIRequests.emitentes.obter(uuid);

            if (dados_emitente.uuid) {
                /**
                 * Armazena o UUID.
                 */
                setUUID(dados_emitente.uuid);

                /**
                 * Carrega os dados adicionais
                 */
                setFormInitialData(dados_emitente);
            } else {
                visible = false;
                new Noty({
                    type: "error",
                    timeout: 2500,
                    text: "Falha ao carregar os dados do emitente.",
                }).show();
            }
        }

        if (visible) {
            setVisible(visible);
        }
    };

    const onRequestClose = () => {
        setVisible(false);

        // reseta o estado
        setUUID("");
        setFormInitialData({});
    };

    const onKeyDown = (e) => {
        // pega o código pressionado
        const code = e.which !== false ? e.which : e.keyCode;

        // stop events
        if (code == 13 || code == 27) {
            e.preventDefault();
        }

        if (code == 13) {
            onRequestConfirm();
        }

        if (code == 27) {
            onRequestClose();
        }
    };

    /**
     * Carrega os dados no form
     */
    useEffect(() => {
        if (FormREF?.current) {
            if (FormInitialData?.uuid) {
                FormREF?.current.setData(FormInitialData);
            } else {
                FormREF?.current.reset();
            }
        }
    }, [isVisible, FormREF, FormInitialData]);

    /**
     * Passa a função de buscar para fora do input via ref.
     */
    useImperativeHandle(ref, () => ({
        open: onRequestOpen,
        close: onRequestClose,
    }));

    return (
        <Modal isVisible={isVisible} setVisible={onRequestClose} closeButtonVisible={false} closeOnClickOutside={false} width={650} height={"100%"}>
            <Main>
                <ModalHeader>{UUID ? "Edição" : "Cadastro"} de Emitente</ModalHeader>

                <Content>
                    <Form ref={FormREF} onSubmit={onSubmit}>
                        <Row>
                            <ColSm4>
                                <Label>
                                    CPF/CNPJ *
                                    <SubLabel style={{ cursor: "pointer" }} onClick={onRequestSearchCNPJ}>
                                        <i className="fad fa-search"></i> Buscar
                                    </SubLabel>
                                </Label>
                                <InputFormNormal autoFocus name="documento" mask="cpf_cnpj" onKeyDown={onKeyDown} />
                            </ColSm4>

                            <ColSm8>
                                <Label>Nome / Razão Social *</Label>
                                <InputFormNormal name="nome" onKeyDown={onKeyDown} />
                            </ColSm8>
                        </Row>

                        <Row>
                            <ColSm12>
                                <Label>Fantasia</Label>
                                <InputFormNormal name="fantasia" onKeyDown={onKeyDown} />
                            </ColSm12>
                        </Row>

                        <br />
                        <Hr />
                        <br />

                        <Row>
                            <ColSm8>
                                <Label>Município *</Label>
                                <InputFormNormal name="municipio" onKeyDown={onKeyDown} />
                            </ColSm8>
                            <ColSm4>
                                <Label>UF *</Label>
                                <InputFormNormal name="uf" onKeyDown={onKeyDown} />
                            </ColSm4>
                        </Row>

                        <br />
                        <Hr />
                        <br />

                        <Row>
                            <ColSm4>
                                <Label>E-mail</Label>
                                <InputFormNormal name="email" onKeyDown={onKeyDown} />
                            </ColSm4>

                            <ColSm4>
                                <Label>Telefone</Label>
                                <InputFormNormal name="telefone" mask="phone" onKeyDown={onKeyDown} />
                            </ColSm4>

                            <ColSm4>
                                <Label>Celular</Label>
                                <InputFormNormal name="celular" mask="phone" onKeyDown={onKeyDown} />
                            </ColSm4>
                        </Row>

                        {UUID && (
                            <>
                                <br />
                                <Hr />
                                <br />

                                <Row>
                                    <ColSm12>
                                        <Label>Token</Label>
                                        <InputFormNormal name="token" disabled />
                                    </ColSm12>
                                </Row>
                            </>
                        )}
                        <Footer>
                            <ButtonSecondary type="button" onClick={onRequestClose}>
                                Fechar
                            </ButtonSecondary>

                            {AuthService.checkPermission("emitentes", "editar") && (
                                //
                                <ButtonPrimary type="submit">OK</ButtonPrimary>
                            )}
                        </Footer>
                    </Form>
                </Content>
            </Main>
        </Modal>
    );
});

export default ModalCadastroEdicao;
