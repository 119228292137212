const AS_LAST_USER_LOGGED = '@painel-pix/last_user_logged'; // email
const AS_USER_TOKEN = '@painel-pix/user_token';
const AS_USER_DATA = '@painel-pix/user_data';

const CONSTS_AS = {
    AS_LAST_USER_LOGGED,
    AS_USER_TOKEN,
    AS_USER_DATA
}

export default CONSTS_AS;