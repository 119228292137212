interface ListPermissoesType {
    titulo: string;
    permissao: string;
}

interface ListModulosType {
    grupo: string;
    titulo: string;
    modulo: string;

    permissoes: ListPermissoesType[];
}

export interface ModulosListType {
    grupo: string;
    titulo: string;
    modulos: ListModulosType[];
}

const ListarModulos = (): ModulosListType[] => {
    const modulos: ModulosListType[] = [
        {
            grupo: "cadastros",
            titulo: "Cadastros",
            modulos: [
                {
                    grupo: "cadastros",
                    titulo: "Administradores",
                    modulo: "administradores",

                    permissoes: [
                        { titulo: "Listagem", permissao: "listagem" },
                        { titulo: "Adicionar", permissao: "adicionar" },
                        { titulo: "Editar", permissao: "editar" },
                    ],
                },

                {
                    grupo: "cadastros",
                    titulo: "Emitentes",
                    modulo: "emitentes",

                    permissoes: [
                        { titulo: "Listagem", permissao: "listagem" },
                        { titulo: "Adicionar", permissao: "adicionar" },
                        { titulo: "Editar", permissao: "editar" },
                    ],
                },

                {
                    grupo: "cadastros",
                    titulo: "Emitentes PSP",
                    modulo: "emitentes psp",

                    permissoes: [
                        { titulo: "Listagem", permissao: "listagem" },
                        { titulo: "Adicionar", permissao: "adicionar" },
                        { titulo: "Editar", permissao: "editar" },
                        { titulo: "Certificado Digital", permissao: "certificado digital" },
                    ],
                },
            ],
        },

        {
            grupo: "ferramentas",
            titulo: "Cadastros",
            modulos: [
                {
                    grupo: "ferramentas",
                    titulo: "Certificado Digital",
                    modulo: "certificado digital",

                    permissoes: [
                        { titulo: "Chave Pública", permissao: "chave publica" },
                        { titulo: "Chave Privada", permissao: "chave privada" },
                    ],
                },
            ],
        },
    ];

    return modulos;
};

const ModulosDisponiveisService = {
    ListarModulos,
};

export default ModulosDisponiveisService;
