/**
 * Firebase
 */
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

/**
 * Configura o Firebase
 */
const app = initializeApp({
    apiKey: "AIzaSyDXn8GCxQAox-NMt_DNnqULAWZRcOzrjN0",
    authDomain: "painel-pix.firebaseapp.com",
    projectId: "painel-pix",
    storageBucket: "painel-pix.appspot.com",
    messagingSenderId: "778354006791",
    appId: "1:778354006791:web:be73d89c60efb10eaa43a2",
    measurementId: "G-LSB3HSWPRX"
});

/**
 * Configura o analytics
 */
const analytics = getAnalytics(app);