/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useEffect, useState, forwardRef, useImperativeHandle } from "react";

// BOOTSTRAP
import Row from "~/components/Bootstrap/Row";
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm6, ColSm8 } from "~/components/Bootstrap/Col";
import InputGroup from "~/components/Bootstrap/InputGroup";
import Label from "~/components/Bootstrap/Label";

// SERVICES
import AuthService from "~/services/auth";
import APIRequests from "~/services/requests/api";
import ModulosDisponiveisService, { ModulosListType } from "~/services/modulos-disponiveis";

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";

// STYLED COMPONENTS
import { Main, Content, Footer } from "./styles";

// INPUTS
import InputNormal from "~/components/Inputs/Normal/Normal";
import SelectNormal from "~/components/Selects/Normal/Normal";

// BUTONS
import ButtonPrimary from "~/components/Buttons/Normal/Primary";
import ButtonSecondary from "~/components/Buttons/Normal/Secondary";

// UTILS
import Utils from "~/utils";
import numeral from "~/utils/numeral";
import NotificacaoUtil from "~/utils/notificacao";

export interface ModalCadastroEdicaoAdministradoresForwardRef {
    open: (uuid: string) => Promise<void>;
    close: () => any;
}

interface Props {
    ref: React.ForwardedRef<React.FC>;

    onConfirmCadEdit?: () => void;
}

const ModalCadastroEdicaoAdministradores = forwardRef<ModalCadastroEdicaoAdministradoresForwardRef, Props>(({ onConfirmCadEdit = () => {} }, ref) => {
    const Modulos = ModulosDisponiveisService.ListarModulos();

    const [isVisible, setVisible] = useState(false);

    const [UUID, setUUID] = useState("");
    const [Nome, setNome] = useState("");
    const [Email, setEmail] = useState("");
    const [Senha, setSenha] = useState("");
    const [Celular, setCelular] = useState("");
    const [Master, setMaster] = useState(false);
    const [Situacao, setSituacao] = useState("ativo");

    const [Permissoes, setPermissoes] = useState<any>([]);

    /**
     * Checkbox que seleciona o grupo
     */
    const onChangeCheckboxGroup = (grupo, event) => {
        /**
         * Checkbox está marcado ou não?
         */
        const checked = event.target.checked;

        /**
         * Permissões geradas no final
         */
        const permissoes_geradas: any = [];

        for (const modulo of grupo.modulos) {
            for (const permissao of modulo.permissoes) {
                permissoes_geradas.push({
                    modulo: modulo.modulo,
                    permissao: permissao.permissao,
                });
            }
        }

        /**
         * Atualiza as permissões do usuário
         */
        setPermissoes((old) => {
            let permissions: any = [...old];

            if (checked) {
                permissions.push(...permissoes_geradas);
            } else {
                for (const perm of permissoes_geradas) {
                    permissions = permissions.filter((p, i) => p.modulo != perm.modulo && p.permissao != perm.permissao);
                }
            }

            return permissions;
        });
    };

    /**
     * Checkbox que seleciona o módulo
     */
    const onChangeCheckboxModule = (modulo, event) => {
        /**
         * Checkbox está marcado ou não?
         */
        const checked = event.target.checked;

        /**
         * Permissões geradas no final
         */
        const permissoes_geradas: any = [];

        for (const permissao of modulo.permissoes) {
            permissoes_geradas.push({
                modulo: modulo.modulo,
                permissao: permissao.permissao,
            });
        }

        /**
         * Atualiza as permissões do usuário
         */
        setPermissoes((old) => {
            let permissions: any = [...old];

            if (checked) {
                permissions.push(...permissoes_geradas);
            } else {
                for (const perm of permissoes_geradas) {
                    permissions = permissions.filter((p, i) => !(p.modulo == perm.modulo && p.permissao == perm.permissao));
                }
            }

            return permissions;
        });
    };

    /**
     * Checkbox que seleciona o grupo
     */
    const onChangeCheckboxAccess = (modulo, acesso, event) => {
        /**
         * Checkbox está marcado ou não?
         */
        const checked = event.target.checked;

        /**
         * Atualiza as permissões do usuário
         */
        setPermissoes((old) => {
            let permissions: any = [...old];

            if (checked) {
                permissions.push({
                    modulo: modulo.modulo,
                    permissao: acesso.permissao,
                });
            } else {
                permissions = permissions.filter((p, i) => !(p.modulo == modulo.modulo && p.permissao == acesso.permissao));
            }

            return permissions;
        });
    };

    const onRequestConfirm = async () => {
        try {
            if (!Nome) {
                throw new Error("Informe o nome");
            }

            if (!Email) {
                throw new Error("Informe o e-mail");
            }

            if (!Senha) {
                throw new Error("Informe a senha");
            }

            if (!Celular) {
                throw new Error("Informe o celular");
            }

            const objeto_enviar = {
                nome: Nome,
                email: Email,
                senha: Senha,
                celular: Celular,
                situacao: Situacao,
                permissoes: Permissoes,
            };

            let retorno_api: any = {};
            if (UUID) {
                retorno_api = await APIRequests.administradores.atualizar(UUID, objeto_enviar);
            } else {
                retorno_api = await APIRequests.administradores.adicionar(objeto_enviar);
            }

            if (retorno_api?.id) {
                NotificacaoUtil.success({
                    msg: UUID ? `Administrador atualizado com sucesso` : "Administrador cadastrado com sucesso",
                });

                // fecha o modal
                onRequestClose();

                // dispara a função externa
                onConfirmCadEdit();
            } else {
                NotificacaoUtil.error({
                    msg: UUID ? `Erro ao atualizar o administrador` : `Erro ao cadastrar o administrador`,
                    timeout: 3500,
                });
            }
        } catch (error: Error | any) {
            NotificacaoUtil.error({
                msg: error.message,
                timeout: 3500,
            });
        }
    };

    const onRequestOpen = async (uuid = "") => {
        /**
         * Busca os dados do usuário
         */
        const user = await AuthService.getUserData();

        let visible = true;

        if (uuid) {
            const dados_administrador: any = await APIRequests.administradores.obter(uuid);

            if (dados_administrador.uuid) {
                setUUID(dados_administrador.uuid);
                setNome(dados_administrador.nome);
                setEmail(dados_administrador.email);
                setSenha(dados_administrador.senha);
                setMaster(dados_administrador.master);
                setCelular(dados_administrador.celular);
                setSituacao(dados_administrador.situacao);

                /**
                 * Preenche as permissões
                 */
                setPermissoes(
                    [...(dados_administrador?.permissoes || [])]?.map((p) => ({
                        modulo: p.modulo,
                        permissao: p.acesso,
                    })),
                );
            } else {
                visible = false;
                new Noty({
                    type: "error",
                    timeout: 2500,
                    text: "Falha ao carregar os dados do administrador.",
                }).show();
            }
        }

        if (visible) {
            setVisible(visible);
        }
    };

    const onRequestClose = () => {
        setVisible(false);

        // reseta o estado
        setUUID("");
        setNome("");
        setEmail("");
        setSenha("");
        setCelular("");
        setSituacao("ativo");
        setMaster(false);
        setPermissoes([]);
    };

    const onKeyDown = (e) => {
        // pega o código pressionado
        const code = e.which !== false ? e.which : e.keyCode;

        // stop events
        if (code == 13 || code == 27) {
            e.preventDefault();
        }

        if (code == 13) {
            onRequestConfirm();
        }

        if (code == 27) {
            onRequestClose();
        }
    };

    /**
     * Passa a função de buscar para fora do input via ref.
     */
    useImperativeHandle(ref, () => ({
        open: onRequestOpen,
        close: onRequestClose,
    }));

    return (
        <Modal isVisible={isVisible} setVisible={onRequestClose} closeButtonVisible={false} closeOnClickOutside={false} width={900} height={"100%"}>
            <Main>
                <ModalHeader>{UUID ? "Edição" : "Cadastro"} de Administrador</ModalHeader>

                <Content>
                    <Row>
                        <ColSm8>
                            <Label>
                                Nome
                                {Master && <div style={{ float: "right", color: "#a93939", fontWeight: 600 }}>Usuário Master</div>}
                            </Label>
                            <InputNormal value={Nome} onKeyDown={onKeyDown} onChange={(e) => setNome(e.target.value)} />
                        </ColSm8>

                        <ColSm4>
                            <Label>Celular</Label>
                            <InputNormal mask="phone" value={Celular} onKeyDown={onKeyDown} onChange={(e) => setCelular(e.target.value)} />
                        </ColSm4>
                    </Row>

                    <Row>
                        <ColSm6>
                            <Label>Email</Label>
                            <InputNormal value={Email} onKeyDown={onKeyDown} onChange={(e) => setEmail(e.target.value)} />
                        </ColSm6>
                        <ColSm6>
                            <Label>Senha</Label>
                            <InputNormal value={Senha} type={Master ? "password" : ""} onKeyDown={onKeyDown} onChange={(e) => setSenha(e.target.value)} />
                        </ColSm6>
                    </Row>

                    <Row>
                        <ColSm12>
                            <Label>Situação</Label>
                            <SelectNormal value={Situacao} onChange={(e) => setSituacao(e.target.value)} onKeyDown={onKeyDown}>
                                <option value={"ativo"}>Ativo</option>
                                <option value={"inativo"}>Inativo</option>
                            </SelectNormal>
                        </ColSm12>
                    </Row>

                    <br />
                    <Row>
                        <ColSm12>
                            <div id="main-perm" style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", flexWrap: "wrap" }}>
                                {Modulos.map((grupo, i) => {
                                    return grupo.modulos.map((modulo) => {
                                        {
                                            /* <div style={{ display: 'flex', marginLeft: 50, flexDirection: 'row', flexWrap: 'wrap', color: '#0F669B' }}> */
                                        }
                                        return (
                                            <div key={`module_${modulo.modulo}`} style={{ marginBottom: 10, minWidth: 255 }}>
                                                <div style={{ color: "" }}>
                                                    <label>
                                                        <input
                                                            disabled={Master}
                                                            checked={Permissoes.findIndex((p) => p.modulo == modulo.modulo) != -1}
                                                            name={`module_${modulo.modulo}`}
                                                            type="checkbox"
                                                            onChange={(e) => onChangeCheckboxModule(modulo, e)}
                                                        />
                                                        {grupo.titulo} {">"} {modulo.titulo}
                                                    </label>
                                                </div>

                                                <div>
                                                    {modulo.permissoes.map((acesso) => {
                                                        return (
                                                            <div style={{ color: "", marginLeft: 5 }} key={`module_${modulo.modulo} permission_${acesso.permissao}`}>
                                                                <label>
                                                                    <input
                                                                        disabled={Master}
                                                                        checked={Permissoes.findIndex((p) => p.modulo == modulo.modulo && p.permissao == acesso.permissao) != -1}
                                                                        className={`module_${modulo.modulo} permission_${acesso.permissao}`}
                                                                        type="checkbox"
                                                                        onChange={(e) => onChangeCheckboxAccess(modulo, acesso, e)}
                                                                    />
                                                                    {acesso.titulo}
                                                                </label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    });
                                })}
                            </div>
                        </ColSm12>
                    </Row>

                    <Footer>
                        <ButtonSecondary onClick={onRequestClose}>Fechar</ButtonSecondary>

                        <ButtonPrimary onClick={onRequestConfirm}>OK</ButtonPrimary>
                    </Footer>
                </Content>
            </Main>
        </Modal>
    );
});

export default ModalCadastroEdicaoAdministradores;
