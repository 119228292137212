const default_dt = { msg: "", timeout: 2500, show: true };

interface MessagePayload {
    msg: string;
    timeout?: number;
    show?: boolean;
}

const success = (dt: MessagePayload = default_dt) => {
    dt = {
        ...default_dt,
        ...dt,
    };

    const noty = new Noty({
        type: "success",
        timeout: dt.timeout,
        text: dt.msg,
    });

    if (dt.show) {
        noty.show();
    }

    return noty;
};

const error = (dt: MessagePayload = { ...default_dt, timeout: 3500 }) => {
    dt = {
        ...default_dt,
        ...dt,
    };

    const noty = new Noty({
        type: "error",
        timeout: dt.timeout,
        text: dt.msg,
    });

    if (dt.show) {
        noty.show();
    }

    return noty;
};

const NotificacaoUtil = {
    success: success,
    error: error,
};

export default NotificacaoUtil;
