import styled from 'styled-components'

import InputBase from '~/components/InputBase';

const InputBaseSmall = styled(InputBase)`
    width: calc(100% - 24px - 2px); // padding * 2 = 12px    2px = 1px border * 2
    height: 18px;
    padding: 6px 12px;
    font-size: 15px;

    /* CORRIGE EFEITO FEIO DO INPUT SMALL */
    /* SE MUDAR O TANTO DE PADDING, TEM QUE ARRUMAR O WIDTH */
    &[type="file"] {
        padding-left: 4px;
        padding-right: 20px;
        padding-top: 4px;
        padding-bottom: 8px;
    }
`;

export default InputBaseSmall;