import React, { useRef } from "react";
import { useSpring, animated } from "react-spring";

// STYLED COMPONENTS
import { Background, ModalWrapper, ModalContent, MHeader, CloseModalButton } from "./styles.js";

interface Props extends React.HTMLAttributes<HTMLElement> {
    isVisible: boolean;
    setVisible: (visible: boolean) => any;

    width?: number;
    height?: number | string;

    maxWidth?: number | string;
    maxHeight?: number | string;

    minWidth?: number | string;
    minHeight?: number | string;

    fontColor?: string;
    backgroundColor?: string;
    borderRadius?: number;

    animateTranlate?: boolean;
    animateOpacity?: boolean;

    closeOnClickOutside?: boolean;
    closeButtonVisible?: boolean;
    closeButtonColor?: string;

    children?: React.ReactNode | JSX.Element | JSX.Element;
}

const Modal: React.FC<Props> = ({
    id = "",
    isVisible = false,
    setVisible = () => {},

    style = {},

    animateTranlate = true,
    animateOpacity = true,

    // preventKeypress = true,
    closeButtonVisible = true,
    closeButtonColor = "#fff",
    closeOnClickOutside = true,

    backgroundColor = "#fff",
    fontColor = "#333",

    width = 650,
    height = 400,

    maxWidth = "initial",
    maxHeight = "initial",

    minWidth = "initial",
    minHeight = "initial",

    children,
}) => {
    const modalRef = useRef(null);

    const animation_opacity = useSpring({
        config: {
            duration: 350,
        },
        opacity: animateOpacity == true ? (isVisible ? 1 : 0.5) : 1,
    });

    const animation = useSpring({
        config: {
            duration: 250,
        },
        transform: animateTranlate == true ? (isVisible ? `translateY(0%)` : `translateY(-5%)`) : `translateY(0%)`,
    });

    const handleCloseModal = (e) => {
        if (modalRef.current === e.target) {
            setVisible(false);
        }
    };

    return (
        <>
            {isVisible ? (
                <animated.div id={id ?? ""} style={animation_opacity} className="modal">
                    <Background onClick={closeOnClickOutside == true ? handleCloseModal : null} ref={modalRef}>
                        <animated.div style={animation}>
                            <ModalWrapper
                                isVisible={isVisible}
                                width={width}
                                minWidth={minWidth}
                                maxWidth={maxWidth}
                                height={height}
                                minHeight={minHeight}
                                maxHeight={maxHeight}
                                style={style}
                                background={backgroundColor}>
                                <ModalContent fontColor={fontColor}>{children}</ModalContent>

                                {closeButtonVisible && (
                                    <CloseModalButton color={closeButtonColor} aria-label="Close modal" onClick={() => setVisible(false)}>
                                        <i className="fal fa-times"></i>
                                    </CloseModalButton>
                                )}
                            </ModalWrapper>
                        </animated.div>
                    </Background>
                </animated.div>
            ) : null}
        </>
    );
};

export const ModalHeader = MHeader;

export default Modal;
