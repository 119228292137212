import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Navigate, useNavigate } from "react-router-dom";

// SERVICES
import AuthService from "~/services/auth";
import APIRequests from "~/services/requests/api";

// COMPONENTES GERAIS
import SearchInput from "~/components/SearchInput";

import Row from "~/components/Bootstrap/Row";

// COMPONENTES DE BASEPAGE
import { BasePageMain, BasePageContainer, BasePageBox, BasePageTitleContainer, BasePageTitle, BasePageButtonsContainer, BasePageTopLineSeparator } from "~/components/BasePage";

// TABLE
import Table from "~/components/TableR";

// COMPONENTES GERAIS
import NavigationBar from "~/components/NavigationBar";
import ButtonPrimary from "~/components/Buttons/Small/Primary";

// COMPONENTES INTERNOS
import ModalCadastroEdicao from "./components/ModalCadEdit";

// UTILS
import { ColSm3, ColSm9 } from "~/components/Bootstrap/Col";

export default function PageCadastroEmitentes() {
    const RefModalCad = useRef(null);

    const navigate = useNavigate();

    /**
     * Dados de navegação
     */
    const [NavSorted, setNavSorted] = useState([]);
    const [NavPageCount, setNavPageCount] = useState(0);
    const [NavCurrentPage, setNavCurrentPage] = useState(1);
    const [NavLimitPerPage, setNavLimitPerPage] = useState(10);
    const [NavTotalRows, setNavTotalRows] = useState(0);

    /**
     * Lista de Emitentes
     */
    const [Emitentes, setEmitentes] = useState([]);

    const [Pesquisa, setPesquisa] = useState("");
    const [Pesquisar, setPesquisar] = useState(false);

    /**
     * Carrega os Emitentes da
     */
    const goLoadEmitentes = async () => {
        const Emitentes = await APIRequests.emitentes.listar({
            query: Pesquisa,
            page: NavCurrentPage,
            limit: NavLimitPerPage,
            sorted: NavSorted,
        });

        if (Emitentes.results) {
            setEmitentes(Emitentes.results);
            setNavPageCount(Emitentes.metadata.page.total);
            setNavTotalRows(Emitentes.metadata.total);
        }
    };

    /**
     * Quando navegar na tabela
     */
    const onTableNavChange = useCallback((data) => {
        setNavSorted(data.sortBy);
        setNavLimitPerPage(data.limit);
        setNavCurrentPage(data.page + 1);
    }, []);

    /**
     * Abre o modal para inserir.
     */
    const onRequestAddEmitente = () => {
        RefModalCad?.current?.open();
    };

    /**
     * Abre o modal para editar.
     */
    const onRequestEditEmitente = (uuid) => {
        RefModalCad?.current?.open(uuid);
    };

    /**
     * Ao confirmar o cadastro ou edição do Emitente.
     */
    const onConfirmCadEditEmitente = () => {
        goLoadEmitentes();
    };

    /**
     * Dispara requisições para backend.
     */
    useEffect(() => {
        let timeout = setTimeout(() => {
            goLoadEmitentes();
        }, 100);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        };
    }, [Pesquisa, NavLimitPerPage, NavCurrentPage, NavSorted]);

    /**
     * Colunas da tabela
     */
    const TableColumns = useMemo(
        () => [
            {
                accessor: "uuid",
                width: 40,
                disableSortBy: true,
                Header: (data) => {
                    return <center>#</center>;
                },
                Cell: ({ value, row }) => {
                    return (
                        <center>
                            <ButtonPrimary
                                // disabled={!AuthService.checkPermission('emitentes', 'editar')}
                                onClick={() => onRequestEditEmitente(value)}>
                                <i className="fas fa-eye" />
                            </ButtonPrimary>
                            <ButtonPrimary
                                // disabled={!AuthService.checkPermission('emitentes', 'editar')}
                                onClick={() => navigate(`/app/cadastros/emitentes/${value}/psps`)}>
                                <i className="fas fa-link" />
                            </ButtonPrimary>
                        </center>
                    );
                },
            },
            {
                Header: "Nome",
                accessor: "nome",
            },
            {
                Header: "Fantasia",
                accessor: "fantasia",
            },
            {
                Header: "CPF/CNPJ",
                accessor: "documento",
                width: 70,
            },
            {
                Header: "Telefone",
                accessor: "telefone",
                width: 60,
            },
            {
                Header: "Celular",
                accessor: "celular",
                width: 60,
            },
        ],
        [],
    );

    const TableInitialSortBy = [
        {
            id: "nome",
            desc: true,
        },
    ];

    return (
        <BasePageMain id="main">
            <NavigationBar />

            <BasePageContainer id="container">
                <BasePageTitleContainer>
                    <BasePageTitle>Cadastro de Emitentes</BasePageTitle>
                </BasePageTitleContainer>

                {AuthService.checkPermission("emitentes", "adicionar") && (
                    <>
                        <BasePageTopLineSeparator />

                        <BasePageButtonsContainer>
                            <ButtonPrimary onClick={onRequestAddEmitente}>Adicionar</ButtonPrimary>
                        </BasePageButtonsContainer>
                    </>
                )}

                <BasePageBox id="box">
                    <Row>
                        <ColSm9 />
                        <ColSm3>
                            <SearchInput inputType="small" onChange={(e) => setPesquisar(true)} onSearch={(text) => setPesquisa(text)} />
                        </ColSm3>
                    </Row>

                    <Table
                        nostriped
                        initialSortBy={TableInitialSortBy}
                        pageIndex={NavCurrentPage - 1}
                        pageCount={NavPageCount}
                        totalRows={NavTotalRows}
                        initialPageSize={20}
                        data={Emitentes}
                        columns={TableColumns}
                        onChangeNav={onTableNavChange}
                    />

                    {/* <div style={{ marginTop: 10 }}>
                        <Table nostriped>
                            <Thead>
                                <Tr>
                                    <Th width={40}>
                                        <center>#</center>
                                    </Th>
                                    <Th>Nome</Th>
                                    <Th width={220}>E-mail</Th>
                                    <Th width={115}>Celular</Th>
                                    <Th width={100}>Situação</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {Emitentes.map((prod) => {
                                    return (
                                        <Tr key={prod.uuid}>
                                            <Td width={40}>
                                                <center>
                                                    <ButtonPrimary onClick={() => onRequestEditEmitente(prod.uuid)}>
                                                        <i className="fas fa-pencil" />
                                                    </ButtonPrimary>
                                                </center>
                                            </Td>
                                            <Td>{prod.nome}</Td>
                                            <Td width={220}>{prod.email}</Td>
                                            <Td width={115}>{prod.celular}</Td>
                                            <Td width={100} style={{ textTransform: 'capitalize' }}>{prod.situacao}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </div> */}
                </BasePageBox>
            </BasePageContainer>

            <ModalCadastroEdicao ref={RefModalCad} onConfirmCadEdit={onConfirmCadEditEmitente} />
        </BasePageMain>
    );
}
