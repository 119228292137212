import styled from 'styled-components';
import ButtonBase from '~/components/ButtonBase';

const ButtonBaseNormal = styled(ButtonBase)`
    font-size: 16px;

    padding: 0;
    padding: 10px 20px;
    border-radius: 4px;
`;

export default ButtonBaseNormal;