import './configs/firebase';

import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";

/**
 * Rotas default
 */
import MainRoutes from "./routes";

/**
 * Create root
 */
const root = createRoot(document.getElementById('root'));

/**
 * Render default
 */
root.render(
    <React.StrictMode>
        <MainRoutes />
    </React.StrictMode>
);
