// SUB
// import Modulos from './modulos';
import AdministradoresRequests from './administradores';
import EmitentesPSPsRequests from './emitentespsps';
import UtilidadesRequests from './utilidades';
import EmitentesRequests from './emitentes';
// import Relatorios from './relatorios';
import LambdaRequests from './lambda';

const APIRequestsPainel = {

    // modulos: Modulos,

    administradores: AdministradoresRequests,
    emitentes: EmitentesRequests,
    psps: EmitentesPSPsRequests,
    lambda: LambdaRequests,
    utilidades: UtilidadesRequests,

}

export default APIRequestsPainel;